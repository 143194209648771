
.navbar {
  background-color: lightgray;
  padding: 10px 20px !important;
  font-size: 1.2rem;
}

/*This will highlight the active nav */
.active {
  background-color: #ffb154;
  color: white !important;
  border-radius: 5px;
}

button[disabled] {
  background-color: gray !important;
  border: none !important;
  cursor: not-allowed;
}

/*change background when hover*/
.nav-link:hover {
  background-color: #525355;
  color: white !important;
  border-radius: 5px;
}

.left-align {
  text-align: left;
}
